/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateUserRoleDto } from '../models/CreateUserRoleDto';
import type { UpdateUserRoleDto } from '../models/UpdateUserRoleDto';
import type { UserRole } from '../models/UserRole';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UserRolesService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns UserRole
     * @throws ApiError
     */
    public create(
        requestBody: CreateUserRoleDto,
    ): Observable<UserRole> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/user-roles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public findAll(): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/user-roles',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/user-roles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateUserRoleDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/user-roles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/user-roles/{id}',
            path: {
                'id': id,
            },
        });
    }

}