import { Injectable } from '@angular/core';
import { CanActivate, } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AppAuthService } from '../services/core/app-auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private readonly navController: NavController,
		private readonly appAuth: AppAuthService
	) {
	}

	public async canActivate(): Promise<boolean> {

		if (!this.appAuth.isUserLoggedIn) {
			await this.navController.navigateRoot('/login');
			return false;
		}

		return true;
	}
}
