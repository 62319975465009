/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Account } from '../models/Account';
import type { CreateAccountDto } from '../models/CreateAccountDto';
import type { CreateClientsDto } from '../models/CreateClientsDto';
import type { ScenarioPermission } from '../models/ScenarioPermission';
import type { UpdateAccountDto } from '../models/UpdateAccountDto';
import type { UpdateClientsDto } from '../models/UpdateClientsDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AccountService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns Account
     * @throws ApiError
     */
    public createAccount(
        requestBody: CreateAccountDto,
    ): Observable<Account> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/account',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns Account
     * @throws ApiError
     */
    public addClient(
        requestBody: CreateClientsDto,
    ): Observable<Account> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/account/add-client',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getClients(
        id: string,
    ): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/account/clients/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getClientsByUser(
        id: string,
    ): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/account/clients-by-user/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param status
     * @returns any
     * @throws ApiError
     */
    public getAllVideosAccount(
        id: string,
        status: string,
    ): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/account/{id}/all-videos/{status}',
            path: {
                'id': id,
                'status': status,
            },
        });
    }

    /**
     * @returns Account
     * @throws ApiError
     */
    public getAllAccounts(): Observable<Array<Account>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/account/all',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Account
     * @throws ApiError
     */
    public updateAccount(
        id: string,
        requestBody: UpdateAccountDto,
    ): Observable<Account> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/account/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public deleteAccount(
        id: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/account/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns Account
     * @throws ApiError
     */
    public getAccount(
        id: string,
    ): Observable<Account> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/account/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ScenarioPermission
     * @throws ApiError
     */
    public getClientsScenarios(
        id: number,
    ): Observable<Array<ScenarioPermission>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/account/{id}/scenarios',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getClient(
        id: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/account/{id}/client',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public updateClient(
        id: number,
        requestBody: UpdateClientsDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/account/{id}/client',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param status
     * @returns any
     * @throws ApiError
     */
    public deleteAllVideosOfClient(
        id: string,
        status: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/account/{id}/delete-all/{status}',
            path: {
                'id': id,
                'status': status,
            },
        });
    }

}