import { LoadingController, LoadingOptions } from '@ionic/angular';

export const loadingSpinnerAction = async <T>(
    loadingController: LoadingController, 
    action: () => Promise<T>
): Promise<T> => {
    return loadingSpinnerActionWithOptions(loadingController, {}, action);
};

export const loadingSpinnerActionWithOptions = async <T>(
    loadingController: LoadingController,
    options: LoadingOptions,
    action: () => Promise<T>
): Promise<T> => {

    const loadingElement = await loadingController.create(options);
    await loadingElement.present();

    let hasError = false;
    let error = null;
    let result: T | undefined;

    try {
        result = await action();
    } catch (e) {
        error = e;
        hasError = true;
    }

    await loadingElement.dismiss();

    if (hasError) {
        return Promise.reject(error);
    }

    return result!;
};