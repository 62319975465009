import { Pipe, PipeTransform } from '@angular/core';
import { AppAuthService } from '../services/core/app-auth.service';

@Pipe({
  name: 'matchesAdminViewState',
  pure: false
})
export class MatchesAdminViewStatePipe implements PipeTransform {

  constructor(
    private readonly appAuth: AppAuthService
  ) {
  }

  public transform(value: boolean): boolean {
    return value === this.appAuth.isAdminViewActive;
  }
}
