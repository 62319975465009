import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'matchesProdMode'
})
export class MatchesProdModePipe implements PipeTransform {

  public transform(value: boolean): boolean {
    return value === environment.production;
  }
}
