import { NgModule } from '@angular/core';

import {
	ScenariosService,
	ScenarioAssetsService,
	ScenarioLayersService,
	UsersService,
	ScenarioPermissionsService,
	RenderJobsService,
	ScenarioLayerPresetsService,
	RenderJobPresetsService,
	AuthService,
	AccountService
} from '../../_generated/openapi';

@NgModule({
	providers: [
		ScenariosService,
		ScenarioAssetsService,
		ScenarioLayersService,
		UsersService,
		ScenarioPermissionsService,
		RenderJobsService,
		ScenarioLayerPresetsService,
		RenderJobPresetsService,
		AuthService,
		AccountService
	]
})
export class BackendModule {
}
