import { NgModule } from '@angular/core';
import { MatchesAdminViewStatePipe } from './matches-admin-view-state.pipe';
import { IsPopulatedPipe } from './is-populated.pipe';
import { UserDisplayNamePipe } from './user-display-name.pipe';
import { MatchesProdModePipe } from './matches-prod-mode.pipe';
import { RemoveUnderscorePipe } from './removeUnderscore.pipe';
import { TruncatePipe } from './truncate.pipe';
import { CutLinkPipe } from './cut-link.pipe';

@NgModule({
	declarations: [
		MatchesAdminViewStatePipe,
		IsPopulatedPipe,
		UserDisplayNamePipe,
		MatchesProdModePipe,
		RemoveUnderscorePipe,
		CutLinkPipe,
		TruncatePipe,
	],
  exports: [
    MatchesAdminViewStatePipe,
    IsPopulatedPipe,
    UserDisplayNamePipe,
    MatchesProdModePipe,
    RemoveUnderscorePipe,
    CutLinkPipe,
		TruncatePipe,
  ]
})
export class PipesModule {
}
