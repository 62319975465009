/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AuthLoginDto } from '../models/AuthLoginDto';
import type { AuthLoginResultDto } from '../models/AuthLoginResultDto';
import type { AuthProxyResetPasswordDto } from '../models/AuthProxyResetPasswordDto';
import type { AuthRefreshDto } from '../models/AuthRefreshDto';
import type { AuthRegisterDto } from '../models/AuthRegisterDto';
import type { AuthResetPasswordDto } from '../models/AuthResetPasswordDto';
import type { AuthUpdatePasswordDto } from '../models/AuthUpdatePasswordDto';
import type { User } from '../models/User';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AuthService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns AuthLoginResultDto
     * @throws ApiError
     */
    public login(
        requestBody: AuthLoginDto,
    ): Observable<AuthLoginResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AuthLoginResultDto
     * @throws ApiError
     */
    public register(
        requestBody: AuthRegisterDto,
    ): Observable<AuthLoginResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public resetPassword(
        requestBody: AuthResetPasswordDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public logout(): Observable<User> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/logout',
        });
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public logoutAll(): Observable<User> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/logout-all',
        });
    }

    /**
     * @param requestBody
     * @returns AuthLoginResultDto
     * @throws ApiError
     */
    public refresh(
        requestBody: AuthRefreshDto,
    ): Observable<AuthLoginResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/refresh',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns AuthProxyResetPasswordDto
     * @throws ApiError
     */
    public resetPasswordForUser(
        userId: string,
    ): Observable<AuthProxyResetPasswordDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/reset-password/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns AuthLoginResultDto
     * @throws ApiError
     */
    public updatePassword(
        requestBody: AuthUpdatePasswordDto,
    ): Observable<AuthLoginResultDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/auth/update-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}