import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssetSelectModalComponent } from './asset-select-modal.component';

@NgModule({
  imports: [ CommonModule, FormsModule, IonicModule,],
  declarations: [AssetSelectModalComponent],
  exports: [AssetSelectModalComponent]
})
export class AssetSelectModalComponentModule {}
