import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Logger, LogLevel, RxConsole } from '@obsidize/rx-console';
import * as URLParse from 'url-parse';

import { AppAuthService } from './app-auth.service';
import { OpenAPI } from '../../_generated/openapi';
import { environment } from 'src/environments/environment';
import { timeout } from "rxjs/operators";

const locationOnAwake = window.location.href;
const parsedLocationOnAwake = URLParse(locationOnAwake);

if (!environment.production) {
	RxConsole
	.main
	.configure({ level: LogLevel.TRACE })
	.enableDefaultBroadcast();
}

OpenAPI.BASE = environment.apiBaseUrl;

@Injectable({
	providedIn: 'root'
})
export class AppBootstrapService {

	private readonly logger = new Logger('AppBootstrapService');
	private mDidInitialize = false;

	constructor(
		private readonly navController: NavController,
		private readonly appAuth: AppAuthService
	) {
	}

	public async initialize(): Promise<void> {

		this.logger.trace('initialize()');

		if (this.mDidInitialize) {
			this.logger.warn('ignoring duplicate initialize call');
			return;
		}

		this.mDidInitialize = true;
		await this.performInitializeActions();
	}

	private async performInitializeActions(): Promise<void> {

		this.logger.trace('initialize()');

		try {
			if (window.location.href.indexOf('register') !== -1) {
				return;
			}

			await this.navController.navigateRoot('/splash');

			await this.appAuth.refreshFromStorage().pipe(
				timeout(5000)
			).toPromise();

			const { pathname } = parsedLocationOnAwake;
			this.logger.debug(`processing pathname "${pathname}" parsed from awake location: `, parsedLocationOnAwake);

			const invalidEntryPages = ['splash', 'login', 'register'];

			const isValidEntryPath = !!(pathname
				&& pathname.length > 1
				&& !invalidEntryPages.some(p => pathname.endsWith(p)));

			const targetPath = isValidEntryPath
				? pathname
				: '/client/dashboard';

			this.logger.debug('performing navigation with state: ', { pathname, isValidEntryPath, targetPath });
			await this.navController.navigateRoot(targetPath);

		} catch (e) {
			this.logger.debug('failed to perform auto-login, redirecting to login page -> ', e);
			await this.navController.navigateRoot('/login');
		}
	}
}
