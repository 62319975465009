/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateManyRenderJobPresetsDto } from '../models/CreateManyRenderJobPresetsDto';
import type { RenderJobPreset } from '../models/RenderJobPreset';
import type { UpdateRenderJobPresetDto } from '../models/UpdateRenderJobPresetDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RenderJobPresetsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param scenarioId
     * @param requestBody
     * @returns RenderJobPreset
     * @throws ApiError
     */
    public create(
        scenarioId: number,
        requestBody: CreateManyRenderJobPresetsDto,
    ): Observable<Array<RenderJobPreset>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/render-job-presets/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns RenderJobPreset
     * @throws ApiError
     */
    public findAll(): Observable<Array<RenderJobPreset>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/render-job-presets',
        });
    }

    /**
     * @param id
     * @returns RenderJobPreset
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<RenderJobPreset> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/render-job-presets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RenderJobPreset
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateRenderJobPresetDto,
    ): Observable<RenderJobPreset> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/render-job-presets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns RenderJobPreset
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<RenderJobPreset> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/render-job-presets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public removeAll(
        id: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/render-job-presets/delete-all/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param scenarioId
     * @returns any
     * @throws ApiError
     */
    public repeatLast(
        scenarioId: number,
    ): Observable<Array<any>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/render-job-presets/repeat-last/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
        });
    }

    /**
     * @param scenarioId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public uploadUsingCsv(
        scenarioId: number,
        requestBody: Array<string>,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/render-job-presets/add-csv/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param scenarioId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public replaceCsv(
        scenarioId: number,
        requestBody: Array<string>,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/render-job-presets/replace-csv/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}