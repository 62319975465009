/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { ScenarioAsset } from '../models/ScenarioAsset';
import type { UpdateScenarioAssetDto } from '../models/UpdateScenarioAssetDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScenarioAssetsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns ScenarioAsset
     * @throws ApiError
     */
    public findAll(): Observable<Array<ScenarioAsset>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-assets',
        });
    }

    /**
     * @param id
     * @returns ScenarioAsset
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<ScenarioAsset> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-assets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ScenarioAsset
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateScenarioAssetDto,
    ): Observable<ScenarioAsset> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/scenario-assets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ScenarioAsset
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<ScenarioAsset> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/scenario-assets/{id}',
            path: {
                'id': id,
            },
        });
    }

}