/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { AddUserRoleDto } from '../models/AddUserRoleDto';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { User } from '../models/User';
import type { UserRolePermission } from '../models/UserRolePermission';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class UsersService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns User
     * @throws ApiError
     */
    public findAll(): Observable<Array<User>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/users',
        });
    }

    /**
     * @param id
     * @returns User
     * @throws ApiError
     */
    public findOne(
        id: string,
    ): Observable<User> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public update(
        id: string,
        requestBody: UpdateUserDto,
    ): Observable<User> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns User
     * @throws ApiError
     */
    public remove(
        id: string,
    ): Observable<User> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns string
     * @throws ApiError
     */
    public getUserRoles(
        id: string,
    ): Observable<Array<string>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/users/{id}/roles',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns UserRolePermission
     * @throws ApiError
     */
    public addUserRole(
        id: string,
        requestBody: AddUserRoleDto,
    ): Observable<UserRolePermission> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/users/{id}/roles',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param roleName
     * @returns UserRolePermission
     * @throws ApiError
     */
    public removeUserRole(
        userId: string,
        roleName: string,
    ): Observable<UserRolePermission> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/users/{userId}/roles/{roleName}',
            path: {
                'userId': userId,
                'roleName': roleName,
            },
        });
    }

}