import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BackendModule } from './services/backend/backend.module';
import { DirectivesModule } from './directives/directives.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from './pipes/pipes.module';
import { ErrorHandlerInterceptor } from './services/interceptors/error-handler.interceptor';
import { CenterContentComponentModule } from './components/center-content/center-content.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SpinnerComponentModule } from './components/spinner/spinner.module';
import { AssetSelectModalComponentModule } from './shared/asset-select-modal/asset-select-modal.module';


export const playerFactory = () => player;
@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		FormsModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HttpClientModule,
		IonicStorageModule.forRoot({
			name: 'version47-db',
		}),
		BackendModule,
		DirectivesModule,
		PipesModule,
		CenterContentComponentModule,
		LottieModule.forRoot({ player: playerFactory }),
		SpinnerComponentModule,
		AssetSelectModalComponentModule
	],
	providers: [
		{
			provide: RouteReuseStrategy,
			useClass: IonicRouteStrategy,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi: true,
		},
	],
})
export class AppModule { }
