import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
	@Input() height = '100px';

	spinnerOptions: AnimationOptions = {
		path: 'assets/gif/spinner.json',
	};
  constructor() { }

  ngOnInit() {}

}
