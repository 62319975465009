import { Injectable } from '@angular/core';
import { Logger } from '@obsidize/rx-console';
import { includes } from 'lodash';
import { BehaviorSubject, defer, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';
import { AuthLoginDto, AuthLoginResultDto, AuthRefreshDto, AuthRegisterDto, AuthService, OpenAPI, User, UsersService } from '../../_generated/openapi';
import { AppStorageService } from './app-storage.service';

export enum UserRoleType {
	ADMIN = 'ADMIN'
}

@Injectable({
	providedIn: 'root'
})
export class AppAuthService {

	private readonly logger = new Logger('AppAuthService');

	private readonly authStorage = this.storage.rootContext.getSubContext('openapiAuth');
	private readonly accessTokenStorage = this.authStorage.getKeyValuePair('accessToken');
	private readonly refreshTokenStorage = this.authStorage.getKeyValuePair('refreshToken');
	private readonly userEmailStorage = this.authStorage.getKeyValuePair('userEmail');
	private readonly userIdStorage = this.authStorage.getKeyValuePair('userId');

	private mCurrentUser = new BehaviorSubject<User | undefined>(undefined);
	private mCurrentUserRoles: string[] = [];
	private mShowAdminOptions: boolean = true;

	constructor(
		private readonly authService: AuthService,
		private readonly usersService: UsersService,
		private readonly storage: AppStorageService
	) {
	}

	public get currentUserObservable(): Observable<User | undefined> {
		return this.mCurrentUser.asObservable();
	}

	public get currentUser(): User {
		return this.mCurrentUser.getValue() as User;
	}

	public get isUserLoggedIn(): boolean {
		return !!this.currentUser;
	}

	public get isCurrentUserAdmin(): boolean {
		return this.currentUserHasRole(UserRoleType.ADMIN);
	}

	public get showAdminOptions(): boolean {
		return this.mShowAdminOptions;
	}

	public set showAdminOptions(value: boolean) {
		this.mShowAdminOptions = !!value;
	}

	public get isAdminViewActive(): boolean {
		return this.isCurrentUserAdmin && this.showAdminOptions;
	}

	public currentUserHasRole(type: UserRoleType): boolean {
		return includes(this.mCurrentUserRoles, type);
	}

	public getStoredUserEmail(defaultValue?: string): Promise<string | undefined> {
		return this.userEmailStorage.load(defaultValue);
	}

	public logout(): Observable<any> {
		return this.authService.logout().pipe(
			catchError(e => {
				this.logger.warn('logout failure: ', e);
				return of(e);
			}),
			switchMap(() => this.clearLoginStorage())
		);
	}

	public login(options: AuthLoginDto): Observable<User> {
		return this.authService.login(options).pipe(
			switchMap(response => this.saveLoginResult(response))
		);
	}

	public register(options: AuthRegisterDto): Observable<User> {
		return this.authService.register(options).pipe(
			switchMap(response => this.saveLoginResult(response))
		);
	}

	public getCurrentUserRoles(): Observable<string[]> {
		return this.usersService.getUserRoles(this.currentUser.id).pipe(
			tap(v => this.mCurrentUserRoles = v)
		);
	}

	public refreshFromStorage(): Observable<User> {

		this.logger.trace('loadLoggedInUser()');

		return defer(() => Promise.all([
			this.accessTokenStorage.load(),
			this.refreshTokenStorage.load(),
			this.userIdStorage.load(),
		])).pipe(
			switchMap(([accessToken, refreshToken, userId]) => {
				return this.restoreUserLoginSession(accessToken!, refreshToken!, userId!);
			})
		);
	}

	private refresh(options: AuthRefreshDto): Observable<User> {
		return this.authService.refresh(options).pipe(
			switchMap(response => this.saveLoginResult(response))
		);
	}

	private restoreUserLoginSession(accessToken: string, refreshToken: string, userId: string): Observable<User> {

		if (!accessToken) {
			throw new Error('no stored access token found');
		}

		OpenAPI.TOKEN = accessToken;

		if (refreshToken) {
			return this.refresh({ refreshToken });
		}

		if (!userId) {
			throw new Error('no stored user id found');
		}

		return this.usersService.findOne(userId).pipe(
			map(response => response as User)
		);
	}

	private async clearLoginStorage(): Promise<void> {

		this.logger.trace('clearLoginStorage()');

		await this.accessTokenStorage.clear();
		await this.refreshTokenStorage.clear();
		await this.userIdStorage.clear();

		OpenAPI.TOKEN = undefined;
		this.mCurrentUser.next(undefined);
		this.mCurrentUserRoles = [];
	}

	private async saveLoginResult(result: AuthLoginResultDto): Promise<User> {

		this.logger.trace('saveLoginResult()', result);
		const { accessToken, refreshToken, user, userRoles } = result;

		await this.accessTokenStorage.save(accessToken);
		await this.refreshTokenStorage.save(refreshToken);
		await this.userEmailStorage.save(user.email);
		await this.userIdStorage.save(user.id);

		OpenAPI.TOKEN = accessToken;
		this.mCurrentUser.next(user);
		this.mCurrentUserRoles = userRoles;

		await this.getCurrentUserRoles()
			.pipe(timeout(5000))
			.toPromise();

		return user;
	}
}
