import { Injectable } from '@angular/core';
import {  Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Page {
	url: string;
	name: string;
	params?: {
		id: string | number;
	};
}

export enum AdminRoutingEnum {
	ACCOUNTS = '/admin-pages/accounts',
	ACCOUNT_EDIT = '/admin-pages/account-edit/', // :id
	ACCOUNT_CREATE = '/admin-pages/account-create',
	CLIENT_EDIT = '/admin-pages/client-edit/', // :id
	CLIENT_CREATE = '/admin-pages/client-create',
	SCENARIO_EDIT = '/admin-pages/scenario-edit/', // :scenarioId
	SCENARIO_CREATE = '/admin-pages/scenario-create',
}

@Injectable({
  providedIn: 'root'
})
export class PageService {
	mPreviousPageUrl = '';
	mCurrentPage = '';
	mPages: Page[] = [];
	pages$ = new BehaviorSubject<Page[]>([]);

	constructor(private readonly route: Router) {
		if (!this.mPages.length) {
			this.mPages = JSON.parse(sessionStorage.getItem('tabPages') as string) || [];
			this.pages$.next(this.mPages);
		}
	}

	previousCrumbPage(): string {
		sessionStorage.setItem('previousPage', '');
		return this.mPreviousPageUrl || '/client/dashboard';
	}

	setPreviousCrumbPage(): void {
		sessionStorage.setItem('previousPage', this.route.url);
		this.mPreviousPageUrl = this.route.url;
	}

	setPages(pages: Page[]): void {
		this.mPages = pages;
	}

	setSessionStorage(): void {
		sessionStorage.setItem('tabPages', JSON.stringify(this.mPages));
	}

	openPage(data: Page): void {
		const findName = this.mPages.find(p => p.name === data.name);

		if (!findName) {
			this.mPages.push(data);
			this.pages$.next(this.mPages);
		}
		this.closeUnusedPage(data.name);
		this.mCurrentPage = data.url;
		this.setSessionStorage();
		this.route.navigate([data.url]);
	}

	closePage(): void {
		this.mPages.filter((p) => p.name === this.mCurrentPage);
		this.mPages.pop();
		this.mCurrentPage = this.mPages.slice(this.mPages.length -1)[0].url ?? AdminRoutingEnum.ACCOUNTS;
		this.pages$.next(this.mPages);
		this.setSessionStorage();
		this.route.navigate([this.mCurrentPage]);
	}

	closeUnusedPage(name: string): void {
		this.mPages.find((p, index) => {
			if (p?.name === name) {
				this.mPages.splice(++index);
				this.pages$.next(this.mPages);
				return;
			}
		});

	}

	getParams(): Page | undefined {
		return this.mPages.find(p => p.url === this.mCurrentPage);
	}

	getAsyncPages(): Observable<Page[]> {
		return this.pages$.asObservable();
	}

	clear(): void {
		this.mPages = [];
		this.pages$.next([]);
		this.mCurrentPage = '';
		sessionStorage.clear();
	}
}
