import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Logger } from '@obsidize/rx-console';
import { timeout, switchMap } from 'rxjs/operators';

import { OpenAPI } from './_generated/openapi/core/OpenAPI';
import { environment } from 'src/environments/environment';
import { AppAuthService } from './services/core/app-auth.service';
import { AppBootstrapService } from './services/core/app-bootstrap.service';
import { Observable, Subject, timer } from 'rxjs';
import { AccountService, Clients } from './_generated/openapi';
import { AdminRoutingEnum, PageService } from './services/core/page.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	version = environment.version
	userClients!: Observable<Clients[]>;
	loading$ = new Subject<boolean>();

	public showAdobeRenderUX = false;

	private readonly logger = new Logger('AppComponent');

	constructor(
		private readonly http: HttpClient,
		private readonly navController: NavController,
		private readonly appBootstrap: AppBootstrapService,
		public readonly appAuth: AppAuthService,
		public readonly accountService: AccountService,
		public readonly pageService: PageService,
		private router: Router,
	) {
		this.router.events.pipe(
			switchMap((event: any) => {
				switch (true) {
					case event instanceof NavigationStart: {
						this.loading$.next(true);
						break;
					}

					case event instanceof NavigationEnd:
					case event instanceof NavigationCancel:
					case event instanceof NavigationError: {
						return timer(1000);
					}

				}
				return timer(1000);
			})).subscribe(() => {
				this.loading$.next(false);
			});

		document.body.setAttribute('app-version', this.appVersion);
		this.http.get(`${OpenAPI.BASE}/api/version`, { responseType: 'text' }).subscribe((version) => {
			document.body.setAttribute('server-version', version);
		});

		document.body.setAttribute('color-theme', 'dark');
	}

	ngOnInit() {
		this.appBootstrap.initialize()
			.catch(e => this.logger.fatal('app bootstrap error !!! -> ', e));

		this.appAuth.currentUserObservable.subscribe((user) => {
			if (user) {
				this.userClients = this.accountService.getClientsByUser(user.id);
			}
		});
	}

	openAccounts(): void {
		this.pageService.openPage({
			name: 'Accounts',
			url: AdminRoutingEnum.ACCOUNTS,
		});
	}

	closeUnusedPage(name: string): void {
		this.pageService.closeUnusedPage(name);
	}

	public get isProductionMode(): boolean {
		return environment.production;
	}

	public get appVersion(): string {
		const suffix = this.isProductionMode ? '' : '-dev';
		return environment.version + suffix;
	}

	public get hideSideMenu(): boolean {
		return !this.appAuth.isUserLoggedIn;
	}

	public get allowAdminViewToggle(): boolean {
		return this.appAuth.isCurrentUserAdmin;
	}

	public get showAdminView(): boolean {
		return this.appAuth.showAdminOptions;
	}

	public set showAdminView(value: boolean) {
		this.appAuth.showAdminOptions = value;
	}

	public async onLogout(): Promise<void> {

		this.logger.trace('onLogout()');

		try {
			await this.appAuth.logout().pipe(
				timeout(5000)
			).toPromise();

			this.pageService.clear();

		} catch (e) {
			this.logger.warn('caught error during logout: ', e);
		}

		await this.navController.navigateRoot('/login');
	}

}
