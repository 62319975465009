import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-center-content',
	templateUrl: './center-content.component.html',
	styleUrls: ['./center-content.component.scss'],
})
export class CenterContentComponent {

	@Input() public direction: 'row' | 'column' = 'column';
}
