import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  pure: false,
  name: 'removeUnderscore'
})
export class RemoveUnderscorePipe implements PipeTransform {

  transform(text: string): string {
    return text.split('_').join(' ');
  }

}
