import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { ScenarioAsset, ScenariosService } from 'src/app/_generated/openapi';
import { loadingSpinnerAction } from 'src/app/lambda/loading-spinner-action';
import { ToastPrefabService } from 'src/app/services/ui/toast-prefab.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-asset-select-modal',
  templateUrl: './asset-select-modal.component.html',
  styleUrls: ['./asset-select-modal.component.scss'],
})
export class AssetSelectModalComponent implements OnInit {
  data: ScenarioAsset[] = [];
  layer: any
  thumbnailUrl = environment.imageUrl
  vidoframe = '#1'
  scenarioId!: number;
  assetType = '';
  public assetFiles: File[] | null = null;
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  constructor(private navParams: NavParams,
    private modalController: ModalController,
    private readonly scenariosService: ScenariosService,
    private readonly loadingController: LoadingController,
    private toastController: ToastPrefabService,
  ) { }

  ngOnInit() {
    const data = this.navParams.get('data');
    this.scenarioId = this.navParams.get('scenarioId');
    this.assetType = this.navParams.get('assetType');
    this.data = data;
  }

  close() {
    this.modalController.dismiss();
  }
  selectedImage(imageUrl: ScenarioAsset) {
    this.modalController.dismiss(imageUrl);
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }
  async onAddScenarioAsset() {
    try {
      const assets = await this.scenariosService.uploadAssetFiles(this.scenarioId, { files: this.assetFiles as File[] }).toPromise();
      if (assets.length > 0) {
        assets.forEach(asset => {
          if (!this.data.some(existingAsset => existingAsset.id === asset.id)) {
            this.data.push(asset);
          }
        })
      }
      await this.toastController.successful('Assets Added successful!');
      return assets
    } catch (e) {
      console.log(e)
    }
  }

  public async onAssetsFileSelectionChange(ev: any): Promise<void> {
    const files = ev?.target?.files;
    this.assetFiles = files ? Array.from(files) : null;
    await loadingSpinnerAction(
      this.loadingController,
      () => this.onAddScenarioAsset()
    );
  }

  getImageName(asset: any): string {
    if (asset?.fileUrl.startsWith("http")) {
      return asset?.fileUrl
    }
    return this.thumbnailUrl + encodeURIComponent(asset?.fileUrl).replace(/'/g, "%27").replace(/"/g, "%22");
  }

}
