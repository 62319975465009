import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SpinnerComponent } from './spinner.component';
import { CenterContentComponentModule } from "../center-content/center-content.module";
import { LottieModule } from "ngx-lottie";

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, CenterContentComponentModule, LottieModule,],
  declarations: [SpinnerComponent],
  exports: [SpinnerComponent]
})
export class SpinnerComponentModule {}
