/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateScenarioLayerDto } from '../models/CreateScenarioLayerDto';
import type { ScenarioLayer } from '../models/ScenarioLayer';
import type { ScenarioLayerPreset } from '../models/ScenarioLayerPreset';
import type { UpdateScenarioLayerDto } from '../models/UpdateScenarioLayerDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScenarioLayersService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param scenarioId
     * @param requestBody
     * @returns ScenarioLayer
     * @throws ApiError
     */
    public addLayer(
        scenarioId: number,
        requestBody: CreateScenarioLayerDto,
    ): Observable<ScenarioLayer> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenario-layers/{scenarioId}',
            path: {
                'scenarioId': scenarioId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ScenarioLayer
     * @throws ApiError
     */
    public findAll(): Observable<Array<ScenarioLayer>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-layers',
        });
    }

    /**
     * @param id
     * @returns ScenarioLayer
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<ScenarioLayer> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-layers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ScenarioLayer
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateScenarioLayerDto,
    ): Observable<ScenarioLayer> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/scenario-layers/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ScenarioLayer
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<ScenarioLayer> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/scenario-layers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ScenarioLayerPreset
     * @throws ApiError
     */
    public getPresets(
        id: number,
    ): Observable<Array<ScenarioLayerPreset>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-layers/{id}/presets',
            path: {
                'id': id,
            },
        });
    }

}