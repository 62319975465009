/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateScenarioPermissionDto } from '../models/CreateScenarioPermissionDto';
import type { ScenarioPermission } from '../models/ScenarioPermission';
import type { UpdateScenarioPermissionDto } from '../models/UpdateScenarioPermissionDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScenarioPermissionsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param scenarioId
     * @param userId
     * @param requestBody
     * @returns ScenarioPermission
     * @throws ApiError
     */
    public createByUserId(
        scenarioId: number,
        userId: number,
        requestBody: CreateScenarioPermissionDto,
    ): Observable<ScenarioPermission> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenario-permissions/{scenarioId}/user/{userId}',
            path: {
                'scenarioId': scenarioId,
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ScenarioPermission
     * @throws ApiError
     */
    public findAll(): Observable<Array<ScenarioPermission>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-permissions',
        });
    }

    /**
     * @param id
     * @returns ScenarioPermission
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<ScenarioPermission> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-permissions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ScenarioPermission
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateScenarioPermissionDto,
    ): Observable<ScenarioPermission> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/scenario-permissions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ScenarioPermission
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<ScenarioPermission> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/scenario-permissions/{id}',
            path: {
                'id': id,
            },
        });
    }

}