import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastPrefabService {

  constructor(
    private readonly toastController: ToastController
  ) {
  }

  public showMessage(
    message: string,
    duration: number = 3000
  ): Promise<HTMLIonToastElement> {
    return this.presentToast({
      message,
      duration,
      position: 'bottom'
    });
  }

	public successful(
		message: string
	): Promise<HTMLIonToastElement> {
		return this.presentToast({
			message,
			duration: 3000,
			position: 'bottom',
			color: 'success',
			cssClass: 'toasty'
		});
	}

  public async presentToast(options: ToastOptions): Promise<HTMLIonToastElement> {
    const toast = await this.toastController.create(options);
    await toast.present();
    return toast;
  }
}
