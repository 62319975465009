import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { StorageContext, StorageTransportApiMask } from '@obsidize/storage-context';

@Injectable({
	providedIn: 'root'
})
export class AppStorageService implements StorageTransportApiMask {

	public readonly rootContext = new StorageContext(this);

	constructor(
		private readonly storage: Storage
	) {
		this.init();
	}

	private init(): void {
		this.storage.create();
	}

	public getItem(key: string): Promise<string | null | undefined> {
		return this.storage.get(key);
	}

	public setItem(key: string, value: string): Promise<void> {
		return this.storage.set(key, value);
	}

	public removeItem(key: string): Promise<void> {
		return this.storage.remove(key);
	}

	public clear(): Promise<void> {
		return this.storage.clear();
	}

	public keys(): Promise<string[]> {
		return this.storage.keys();
	}
}
