/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class GlobalsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getOutputFile(
        id: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/{userId}/render-videos/rendered-video-{id}.mp4',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns string
     * @throws ApiError
     */
    public getVersion(): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/version',
        });
    }

}