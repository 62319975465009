/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddUserRoleDto = {
    role: AddUserRoleDto.role;
};

export namespace AddUserRoleDto {

    export enum role {
        AGENT = 'AGENT',
        ADMIN = 'ADMIN',
        CREATOR = 'CREATOR',
        CONTRIBUTOR = 'CONTRIBUTOR',
    }


}
