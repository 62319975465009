/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Asset } from '../models/Asset';
import type { CreateAssetDto } from '../models/CreateAssetDto';
import type { UpdateAssetDto } from '../models/UpdateAssetDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class AssetsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns Asset
     * @throws ApiError
     */
    public create(
        requestBody: CreateAssetDto,
    ): Observable<Asset> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/assets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Asset
     * @throws ApiError
     */
    public findAll(): Observable<Array<Asset>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assets',
        });
    }

    /**
     * @param id
     * @returns Asset
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<Asset> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/assets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Asset
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateAssetDto,
    ): Observable<Asset> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/assets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns Asset
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<Asset> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/assets/{id}',
            path: {
                'id': id,
            },
        });
    }

}