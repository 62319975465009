/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateLayerPresetsDto } from '../models/CreateLayerPresetsDto';
import type { ScenarioLayerPreset } from '../models/ScenarioLayerPreset';
import type { UpdateScenarioLayerPresetDto } from '../models/UpdateScenarioLayerPresetDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScenarioLayerPresetsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param layerId
     * @param requestBody
     * @returns ScenarioLayerPreset
     * @throws ApiError
     */
    public create(
        layerId: number,
        requestBody: CreateLayerPresetsDto,
    ): Observable<ScenarioLayerPreset> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenario-layer-presets/{layerId}',
            path: {
                'layerId': layerId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ScenarioLayerPreset
     * @throws ApiError
     */
    public findAll(): Observable<Array<ScenarioLayerPreset>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-layer-presets',
        });
    }

    /**
     * @param id
     * @returns ScenarioLayerPreset
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<ScenarioLayerPreset> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenario-layer-presets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ScenarioLayerPreset
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateScenarioLayerPresetDto,
    ): Observable<ScenarioLayerPreset> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/scenario-layer-presets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ScenarioLayerPreset
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<ScenarioLayerPreset> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/scenario-layer-presets/{id}',
            path: {
                'id': id,
            },
        });
    }

}