/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateManyScenarioRenderJobsDto } from '../models/CreateManyScenarioRenderJobsDto';
import type { CreateScenarioDtoWithUrl } from '../models/CreateScenarioDtoWithUrl';
import type { RenderJob } from '../models/RenderJob';
import type { RenderJobPreset } from '../models/RenderJobPreset';
import type { Scenario } from '../models/Scenario';
import type { ScenarioAsset } from '../models/ScenarioAsset';
import type { ScenarioLayer } from '../models/ScenarioLayer';
import type { ScenarioPermission } from '../models/ScenarioPermission';
import type { UpdateScenarioDataClayConfigDto } from '../models/UpdateScenarioDataClayConfigDto';
import type { UpdateScenarioDto } from '../models/UpdateScenarioDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class ScenariosService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @param clientsId
     * @param requestBody
     * @returns Scenario
     * @throws ApiError
     */
    public create(
        clientsId: number,
        requestBody: CreateScenarioDtoWithUrl,
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenarios/{clientsId}',
            path: {
                'clientsId': clientsId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Scenario
     * @throws ApiError
     */
    public findAll(): Observable<Array<Scenario>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios',
        });
    }

    /**
     * @param id
     * @returns Scenario
     * @throws ApiError
     */
    public findOne(
        id: number,
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Scenario
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateScenarioDto,
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/scenarios/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns Scenario
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/scenarios/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Scenario
     * @throws ApiError
     */
    public updateDataClayConfig(
        id: number,
        requestBody: UpdateScenarioDataClayConfigDto,
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/scenarios/{id}/dataclay-config',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns ScenarioPermission
     * @throws ApiError
     */
    public getUsers(
        id: number,
    ): Observable<Array<ScenarioPermission>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/{id}/users',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ScenarioLayer
     * @throws ApiError
     */
    public getLayers(
        id: number,
    ): Observable<Array<ScenarioLayer>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/{id}/layers',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns RenderJobPreset
     * @throws ApiError
     */
    public getJobPresets(
        id: number,
    ): Observable<Array<RenderJobPreset>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/{id}/job-presets',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ScenarioAsset
     * @throws ApiError
     */
    public getTemplateAssets(
        id: number,
    ): Observable<Array<ScenarioAsset>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/{id}/assets',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns RenderJob
     * @throws ApiError
     */
    public getJobs(
        id: number,
    ): Observable<Array<RenderJob>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/{id}/jobs',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RenderJob
     * @throws ApiError
     */
    public addJobs(
        id: number,
        requestBody: CreateManyScenarioRenderJobsDto,
    ): Observable<Array<RenderJob>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenarios/{id}/jobs',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param formData
     * @returns Scenario
     * @throws ApiError
     */
    public uploadProjectFile(
        id: number,
        formData: {
            file: Blob;
        },
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenarios/{id}/upload-project',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @param formData
     * @returns Scenario
     * @throws ApiError
     */
    public uploadTemplateFile(
        id: number,
        formData: {
            file: Blob;
        },
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenarios/{id}/upload-template',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @param formData
     * @returns ScenarioAsset
     * @throws ApiError
     */
    public uploadAssetFiles(
        id: number,
        formData: {
            files: Array<Blob>;
        },
    ): Observable<Array<ScenarioAsset>> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenarios/{id}/upload-assets',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns Scenario
     * @throws ApiError
     */
    public applyJsonTemplate(
        id: number,
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/scenarios/{id}/apply-template',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @param formData
     * @returns Scenario
     * @throws ApiError
     */
    public uploadThumbnail(
        id: number,
        formData: {
            file: Blob;
        },
    ): Observable<Scenario> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/scenarios/{id}/upload-thumbnail',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns RenderJob
     * @throws ApiError
     */
    public getAllVideos(
        id: number,
    ): Observable<Array<RenderJob>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/videos/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns RenderJob
     * @throws ApiError
     */
    public getAllQueuedVideos(
        id: number,
    ): Observable<Array<RenderJob>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/scenarios/queued-videos/{id}',
            path: {
                'id': id,
            },
        });
    }

}