import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	pure: false,
	name: 'cutLink'
})
export class CutLinkPipe implements PipeTransform {

  transform(url: string): string {
    return url.split('/').reverse().slice(0, 1).join();
  }

}
