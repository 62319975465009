/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { CreateFolderDto } from '../models/CreateFolderDto';
import type { RenderJob } from '../models/RenderJob';
import type { UpdateRenderDto } from '../models/UpdateRenderDto';
import type { UpdateRenderJobDto } from '../models/UpdateRenderJobDto';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class RenderJobsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * @returns RenderJob
     * @throws ApiError
     */
    public findAll(): Observable<Array<RenderJob>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/render-jobs',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RenderJob
     * @throws ApiError
     */
    public update(
        id: number,
        requestBody: UpdateRenderJobDto,
    ): Observable<RenderJob> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/render-jobs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns RenderJob
     * @throws ApiError
     */
    public remove(
        id: number,
    ): Observable<RenderJob> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/render-jobs/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public clearRenderQueue(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/render-jobs/clear-render-queue',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns RenderJob
     * @throws ApiError
     */
    public setStatus(
        id: number,
        requestBody: UpdateRenderDto,
    ): Observable<RenderJob> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/api/render-jobs/videos/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getQueuedVideos(): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/render-jobs/queued',
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public getOutputScenarioFiles(
        id: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/render-jobs/output-files/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public createFolder(
        requestBody: CreateFolderDto,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/render-jobs/create/folder',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param path
     * @returns any
     * @throws ApiError
     */
    public getFolder(
        path: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/render-jobs/folder/{path}',
            path: {
                'path': path,
            },
        });
    }

    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public deleteAllVideosOfScenario(
        id: number,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/render-jobs/delete-all/{id}',
            path: {
                'id': id,
            },
        });
    }

}