import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'client/dashboard',
		pathMatch: 'full',
	},
	{
		path: 'splash',
		loadChildren: () => import('./pages/splash/splash.module').then(m => m.SplashPageModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'register',
		loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
	},
	{
		path: 'client/:id',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/scenarios/scenarios.module').then(m => m.ScenariosPageModule)
	},
	{
		path: 'videos',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/videos/videos.module').then(m => m.VideosPageModule)
	},
	{
		path: 'render-queue',
		canActivate: [AuthGuard],
		loadChildren: () => import('./pages/render-queue/render-queue.module').then(m => m.RenderQueuePageModule)
	},
	{
		path: 'admin-pages',
		canActivate: [AuthGuard, AdminGuard],
		loadChildren: () => import('./pages/admin-pages/admin-pages.module').then( m => m.AdminPagesPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
