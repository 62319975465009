import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../_generated/openapi';

@Pipe({
  name: 'userDisplayName',
  pure: false
})
export class UserDisplayNamePipe implements PipeTransform {

  public transform(user?: User): string {
    return user ? `${user.firstName} ${user.lastName}` : 'Unknown User';
  }
}
