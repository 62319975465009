import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppAuthService } from '../services/core/app-auth.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
	constructor(
		private readonly appAuthService: AppAuthService,
		private readonly navController: NavController) {
	}
  canActivate(): boolean {
		if (!this.appAuthService.isCurrentUserAdmin) {
			this.navController.navigateRoot('client/dashboard');
			return false;
		}
    return true;
  }
}
