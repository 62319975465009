import { isArray } from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPopulated',
  pure: false
})
export class IsPopulatedPipe implements PipeTransform {

  public transform(value: any[]): boolean {
    return isArray(value) && value.length > 0;
  }
}
